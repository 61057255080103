* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.Home_Container_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.Home_S1_Ar {
  background-image: url("../../Assets/Svg/Home_BG_Ar.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 75vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
}
.Home_S1_P1_Ar {
  display: flex;
  flex-direction: column;
  padding: 100px 50px;
  width: 45vw;
  gap: 48px;
}
.Home_S1_P1_Ar h1 {
  color: #fff;
  font-family: "Cairo", sans-serif;
  font-size: 3vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: right;
}
.Home_S1_P1_Ar h2 {
  color: #e8e8e8;
  font-family: "Cairo", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}
.Home_S1_P1_BC_Ar {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 12px;
}
.Home_S1_P1_Ar button {
  border-radius: 25px;
  background: #1616a2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14vw;
  height: 7vh;
  padding: 2px 12px;
  flex-shrink: 0;
  gap: 10px;
  color: #fff;
  font-family: "Cairo", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.GifIos_Ar {
  width: 50px;
  margin-top: -5px;
  margin-right: -10px;
  margin-left: -10px;
}
.GifAndroid_Ar {
  width: 35px;
}
.Home_S1_P1_Animation_Ar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.AniMation_L1_Ar {
  display: flex;
  gap: 6px;
}
@keyframes slideInRotate {
  0% {
    transform: translateY(100px) rotate(15deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

.Home_S1_P1_Animation_Ar h1 {
  animation: slideInRotate 1s ease-in backwards;
}

/*  */
/*  */
/*  */
.HomeS2_Container_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.HomeS2_P1_Ar h1 {
  color: #1c1b1f;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  font-family: "Cairo", sans-serif;
  padding-bottom: 30px;
}
.HomeS2_P1_Ar h2 {
  color: rgba(28, 27, 31, 0.9);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Cairo", sans-serif;
}
.HomeS2_P2_Web_Ar {
  width: 100%;
}
.HomeS3_P1_Ar {
  padding-top: 30px;
}
.HomeS2_P2_Web_Ar img {
  width: 100%;
}
.HomeS2CarC_Ar {
  width: 100px !important;
  margin-bottom: -100px;
}
.HomeS2_P2_L1_Ar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3vh 12vw;
}
.S2_P2_L1_L_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.S2_P2_L1_L_Ar h1 {
  width: 300px;
  color: #1c1b1f;
  text-align: center;
  font-family: "Cairo", sans-serif;

  font-size: 2.1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.S2_P2_L1_L_Ar p {
  width: 300px;
  color: rgba(28, 27, 31, 0.9);
  text-align: center;
  font-family: "Cairo", sans-serif;

  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.S2_P2_L1_R_Ar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.S2_P2_L1_R_Ar h1 {
  width: 300px;
  color: #1c1b1f;
  text-align: center;
  font-family: "Cairo", sans-serif;

  font-size: 2.1vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.S2_P2_L1_R_Ar p {
  width: 300px;
  color: rgba(28, 27, 31, 0.9);
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Cairo", sans-serif;
}
.HomeS2CarR_Ar,
.HomeS2CarL_Ar,
.HomeS2CarC_Ar {
  width: 130px !important;
}
.HomeS2CarC_Ar {
  margin-bottom: -100px;
  margin-left: 2vw;
}
.HomeS2CarL_Ar {
  margin-top: -150px;
  margin-left: 2vw;
}
.HomeS2CarR_Ar {
  margin-top: -150px;
  margin-right: 6vw;
}
.Centred_Ar {
  margin-left: 3vw;
}
.Lefted_Ar {
  margin-left: -3vw;
}
.HomeS3_Container_Ar {
  background: #dedcef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7vh 0;
  gap: 7vh;
}
.HomeS3_P1_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.HomeS3_P1_Ar h1 {
  color: #1c1b1f;
  text-align: center;
  font-family: "Cairo", sans-serif;

  font-size: 3.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HomeS3_P1_Ar h2 {
  color: rgba(28, 27, 31, 0.9);
  text-align: center;
  font-family: "Cairo", sans-serif;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.HomeS3_P2_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 264px;
}

.HomeS3_P2_L_Ar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 350px;
}
.HomeS3_P2_L_Ar h1 {
  color: #1c1b1f;
  font-family: "Cairo", sans-serif;

  font-size: 3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.HomeS3_P2_L_Ar p {
  color: #1c1b1f;
  font-family: "Cairo", sans-serif;

  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.HomeS3_P2_L_Ar span {
  color: #1616a2;
}
.HomeS3_P2_Img_Ar {
  position: relative;
}
.Home_P8_S2_Shadow_Right_Ar {
  position: absolute;
  height: 100%;
  right: 0;
  width: 120px;
  background: linear-gradient(-270deg, rgba(234, 232, 255, 0) 0%, #dedcef 100%);
  z-index: 10;
}
.Home_P8_S2_Shadow_left_Ar {
  position: absolute;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 120px;
  background: linear-gradient(270deg, rgba(234, 232, 255, 0) 0%, #dedcef 100%);
  z-index: 10;
}
.HomeS3_P3_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15vw;
}
.HomeS3_P3_Ar img {
  margin-left: -5vw;
}
.HomeS4_Container_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
}
.HomeS4_P1_Ar {
  padding-bottom: 60px;
  font-family: "Cairo", sans-serif;
}
.HomeS4_P2_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
}
.HomeS4_P1_Step_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10vw;
}
.HomeS4_P1_Step_Ar h1 {
  width: 470px;
  font-family: "Cairo", sans-serif;

  text-align: right;
}
.HomeS4_P1_Step_Ar span {
  color: #1616a2;
}
.HomeS2_P2_Mob_Ar {
  display: none;
}
.Home_S1_P1_BC_Ar a,
.Home_S1_P1_BC_Ar a:active,
.Home_S1_P1_BC_Ar a:visited {
  text-decoration: none;
}

@media (max-width: 768px) {
  .Home_S1_Ar {
    background-image: url("../../Assets/Svg/Home_BG_Mob.svg");
    width: 93vw;
    align-items: center;
    border-radius: 30px;
    height: 85vh;
  }
  .Home_S1_P1_Ar h1 {
    color: #fff;
    font-size: 5vw;
    text-align: center;
  }
  .Home_S1_P1_Ar {
    padding: 50px 50px;
    width: 87vw;
    gap: 48px;
  }
  .Home_S1_P1_Ar h2 {
    font-size: 4vw;
    text-align: center;
  }
  .Home_S1_P1_BC_Ar {
    gap: 32px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .Home_S1_P1_Animation_Ar {
    align-items: center;
  }

  .Home_S1_P1_Ar button {
    width: 35vw;
    font-size: 16px;
    margin-left: -20px;
    height: 6vh;
  }
  .GifIos_Ar {
    width: 40px;
    margin-top: -5px;
    margin-right: -10px;
    margin-left: -10px;
  }
  .GifAndroid_Ar {
    width: 25px;
  }
  .HomeS2_Container_Ar {
    padding: 30px 30px;
  }
  .HomeS2_P1_Ar {
    margin-bottom: 5vh;
  }
  .HomeS2_P1_Ar h1 {
    font-size: 5vw;
  }
  .HomeS2_P1_Ar h2 {
    font-size: 4.5vw;
  }
  .HomeS2_P2_Web_Ar {
    display: none;
  }
  .HomeS2LineWeb_Ar {
    display: none;
  }
  .HomeS2_P2_Mob_Ar {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .HomeS2_P2_L1_Mob_Ar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
  .HomeS2_P2_L1_Text_Mob_Ar {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .HomeS2_P2_L1_Mob_Ar img {
    width: 25vw;
  }
  .HomeS2_P2_L1_Mob_Ar h1 {
    color: #1c1b1f;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Cairo", sans-serif;
  }
  .HomeS2_P2_L1_Mob_Ar p {
    color: rgba(28, 27, 31, 0.9);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px; /* 140% */
    width: 210px;
    font-family: "Cairo", sans-serif;
  }
  .RightOne_Ar {
    margin-left: 150px;
  }
  .CenterOne_Ar {
    margin-left: -45vw;
  }
  .HomeS2LineMob_Ar {
    position: absolute;
    left: -50px;
    top: 70px;
  }
  .HomeS3_P2_Ar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
  }
  .HomeS3_P1_Ar h1 {
    font-size: 6.5vw;
    font-family: "Cairo", sans-serif;
  }
  .HomeS3_P1_Ar h2 {
    color: rgba(28, 27, 31, 0.9);
    text-align: center;
    font-size: 3.3vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: "Cairo", sans-serif;
  }
  .HomeS3_P2_L_Ar {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 60vw;
    align-items: center;
  }
  .HomeS3_P2_L_Ar h1 {
    font-size: 6vw;
    text-align: center;
    font-family: "Cairo", sans-serif;
  }

  .HomeS3_P2_L_Ar p {
    font-size: 4.2vw;
    text-align: center;
    font-family: "Cairo", sans-serif;
  }
  .HomeS3_P3_Ar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15vw;
  }
  .HomeS3_P3_Ar img {
    width: 70vw;
  }
  .HomeS4_P1_Step_Ar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10vw;
  }
  .HomeS4_P1_Step_Ar h1 {
    width: 250px;
    color: rgba(28, 27, 31, 0.9);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
  }
  .HomeS4_P1_Step_Ar img {
    width: 40vw;
  }
  .HomeS4_P1_Ar {
    color: #1c1b1f;
    text-align: center;
    font-size: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: "Cairo", sans-serif;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
}
