.About_S1_Ar {
  background-image: url("../../Assets/Svg/About_S1_BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #dedcef;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: "Cairo", sans-serif;
}
.About_S1_P1_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 30px 100px;
}

.About_S1_P1_Ar h1 {
  text-align: center;
  color: #1c1b1f;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  font-family: "Cairo", sans-serif;
}
.About_S2_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
  padding: 10vh 0;
}
.About_S2_P1_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
}
.About_S2_P1_L_Ar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
}
.About_S2_P1_L_Ar h1 {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px; /* 84.375% */
  text-align: center;
  font-family: "Cairo", sans-serif;
}
.About_S2_P1_L_Ar p {
  color: rgba(28, 27, 31, 0.9);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  width: 400px;
  font-family: "Cairo", sans-serif;

  text-align: center;
}
.About_S2_P1_BC_Ar {
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: "Cairo", sans-serif;
}
.About_S2_P1_BC_Ar button {
  border-radius: 25px;
  background: #1616a2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12vw;
  height: 7vh;
  padding: 2px 12px;
  flex-shrink: 0;
  gap: 10px;
  color: #fff;
  font-family: "Cairo", sans-serif;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
}
.About_S2_P1_BC_Ar a,
.About_S2_P1_BC_Ar a:active,
.About_S2_P1_BC_Ar a:visited {
  text-decoration: none;
}
@media (max-width: 768px) {
  .About_S1_Ar {
    background-image: url("../../Assets/Svg/About_S1_BG_Mob.svg");
  }
  .About_S1_P1_Ar img {
    width: 70vw;
  }
  .About_S1_P1_Ar h1 {
    font-size: 5vw;
  }
  .About_One_Ar {
    flex-direction: column-reverse;
  }
  .About_Two_Ar {
    flex-direction: column;
  }
  .About_Three_Ar {
    flex-direction: column-reverse;
  }
  .About_S2_P1_img_Ar {
    width: 50vw;
  }
  .About_S2_P1_L_Ar h1 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; /* 84.375% */
    text-align: center;
  }
  .About_S2_P1_L_Ar p {
    color: rgba(28, 27, 31, 0.9);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px; /* 135% */
    width: 300px;
    text-align: center;
  }
  .About_S2_P1_BC_Ar button {
    border-radius: 25px;
    background: #1616a2;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 125px;
    width: auto;
    height: 7vh;
    padding: 2px 12px;
    flex-shrink: 0;
    gap: 10px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border: none;
    cursor: pointer;
  }
  .About_S2_Ar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10vh;
    padding: 5vh 0;
  }
  .About_S1_P1_Ar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding: 30px 30px;
  }
  .About_S2_P1_L_Ar {
    align-items: center;
  }
}
