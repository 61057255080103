.Footer {
  background-image: url("../../Svg/Footer_BG.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
}
.Footer_P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 5vh;
}
.Footer_P1 a,
.Footer_P1 a:active,
.Footer_P1 a:visited {
  color: #fff;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}
.Footer_P1 h1 {
  color: #fff;
  text-align: center;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 50vw;
  font-family: "Roboto", sans-serif;
}
.Footer_P1 p {
  color: #fff;
  text-align: center;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Roboto", sans-serif;
}
.Footer_P2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.IosFooter {
  width: 70px;
  margin-top: -5px;
  margin-left: -30px;
}
.AndroidFooter {
  width: 35px;
}
.Line_Space,
.Footer_P3 {
  width: 100%;
}
.Footer_P4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding-bottom: 3vh;
}
.Footer_Soical {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.Footer_Copy h1 {
  color: #fff;
  text-align: center;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 768px) {
  .Footer_P1 h1 {
    font-size: 5vw;
    width: 78vw;
  }
  .Footer_P1 p {
    color: #fff;
    text-align: center;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .Footer {
    background-image: url("../../Svg/Footer_BG_Mob.svg");
  }
  .IosFooter {
    width: 50px;
    margin-top: -5px;
    margin-left: -30px;
  }
  .AndroidFooter {
    width: 25px;
  }
  .Footer_Soical img {
    width: 30px;
  }
  .Footer_Copy h1 {
    font-size: 3.2vw;
  }
  .Line_Space,
  .Footer_P3 {
    height: 10px;
  }
}
