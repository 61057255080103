.Footer_Ar {
  background-image: url("../../Svg/Footer_BG.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  font-family: "Cairo", sans-serif;
}
.Footer_P1_Ar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding-top: 5vh;
}
.Footer_P1_Ar a,
.Footer_P1_Ar a:active,
.Footer_P1_Ar a:visited {
  color: #fff;
  text-decoration: none;
}
.Footer_P1_Ar h1 {
  color: #fff;
  text-align: center;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 50vw;
}
.Footer_P1_Ar p {
  color: #fff;
  text-align: center;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.Footer_P2_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.IosFooter_Ar {
  width: 70px;
  margin-top: -5px;
  margin-left: -30px;
}
.AndroidFooter_Ar {
  width: 35px;
}
.Line_Space_Ar,
.Footer_P3_Ar {
  width: 100%;
}
.Footer_P4_Ar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding-bottom: 3vh;
}
.Footer_Soical_Ar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.Footer_Copy_Ar h1 {
  color: #fff;
  text-align: center;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 768px) {
  .Footer_P1_Ar h1 {
    font-size: 5vw;
    width: 78vw;
  }
  .Footer_P1_Ar p {
    color: #fff;
    text-align: center;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .Footer_Ar {
    background-image: url("../../Svg/Footer_BG_Mob.svg");
  }
  .IosFooter_Ar {
    width: 50px;
    margin-top: -5px;
    margin-left: -30px;
  }
  .AndroidFooter_Ar {
    width: 25px;
  }
  .Footer_Soical_Ar img {
    width: 30px;
  }
  .Footer_Copy_Ar h1 {
    font-size: 3.2vw;
  }
  .Line_Space_Ar,
  .Footer_P3_Ar {
    height: 10px;
  }
}
