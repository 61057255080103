.HomeS3_P2_Img {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 35vw;
}
@media (max-width: 768px) {
  .HomeS3_P2_Img {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    width: 100vw;
  }
  .HomeS2Img {
    width: 75vw;
  }
}
