.Header_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.13vw 3.55vw;
}

.Header_Menu {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7vw;
}

.Header_Menu a,
.Header_Menu a:visited,
.Header_Menu a:active {
  text-decoration: none;
  color: #1616a2;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.Header_Menu button {
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.Language_Dropdown {
  position: relative;
}

.Dropdown_Menu {
  position: absolute;
  top: 150%;
  right: -25px;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.Dropdown_Menu button {
  padding: 10px 20px;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  cursor: pointer;
  color: #1616a2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.Header_Text_Ar {
  font-family: "Cairo", sans-serif !important;
}

.Dropdown_Menu button:hover {
  background-color: #6262ea;
  color: #fff;
}
@media (max-width: 768px) {
  .Header_Container {
    padding: 30px 30px;
  }
  .Header_Logo img {
    width: 25vw;
  }
  .Header_Menu a,
  .Header_Menu a:visited,
  .Header_Menu a:active {
    text-decoration: none;
    font-size: 4.2vw !important;
  }
}
