.PrivacyPolicyC {
}
.PRS1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dedcef;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 25vw;
}
.PRS1P1 {
  position: absolute;
  left: 0;
}
.PRS1P3 {
  position: absolute;
  right: 0;
  bottom: -1vw;
}
.PRS1P2 img {
  width: 25vw;
}

.PRS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.3vw 0;
  gap: 2.3vw;
}
.PRS2P1 {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  gap: 2.3vw;
}
.PRS2P1T1 {
  color: #6262ea;
  text-align: center;
  font-family: Roboto;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.ar.PRS2P1T1,
.ar.PRBoxT1,
.ar.PRBoxT2 {
  font-family: Cairo;
}
.PRBox {
  width: 70vw;
  padding: 3.4vw 1.4vw;
  border-radius: 0.7vw;
  background: #f4f4f4;
  box-shadow: 0px 9px 16px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 2.3vw;
}

.PRBoxT2 {
  max-height: 40vw;
  overflow-y: auto;
  padding: 0 2vw;
  margin-right: 0.5vw;
  color: #1c1b1f;
  font-family: Roboto;
  font-size: 0.9vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7vw;
  letter-spacing: -0.324px;
}
.PRBoxT2::-webkit-scrollbar {
  width: 1vw;
}

.PRBoxT2::-webkit-scrollbar-track {
  background: #d5cfe8;
  border-radius: 0.3vw;
}

.PRBoxT2::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0.3vw;
  height: 10vw;
}
.Lo.PRS2P1T1 {
  padding: 2.3vw;
}
.ErrorBC {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  padding: 2vw 0;
}

/* CSS */
.ar.Button button,
.ar.Button a {
  font-family: Cairo;
}
.Button button,
.Button a {
  background-color: #030959;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Button button:before,
.Button a::before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 92%;
}

.Button button:hover,
.Button a:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .button-71 {
    padding: 16px 48px;
  }
}
